import "./index.scss"

import Bar from "@/components/Bar";
// 1. 看官方文档 把 echarts 加入项目
    // 获取dom
// 2. 不抽离定制化的参数 先把最小化的 demo 跑起来
// 3. 按照需求，哪些参数需要自定义 抽象出来

const Home = () => {
    return (
        <div className="home-container">
            {/* 准备一个挂在节点 */}
            <Bar
                title="主流框架使用满意度"
                xData={ [ "react", "vue", "angular" ] }
                yData={ [ 30, 40, 60 ] }
                style={ { width: 500, height: 400 } }
            />
            <Bar
                title="主流框架使用满意度"
                xData={ [ "react", "vue", "angular" ] }
                yData={ [ 50, 90, 70 ] }
                style={ { width: 500, height: 400 } }
            />
        </div>
    )
}

export default Home;
