import "./App.css";
import "./../node_modules/antd/dist/antd";

import { unstable_HistoryRouter as HistoryRouter, Routes, Route } from "react-router-dom";
import { AuthComponent } from "./components/AuthComponent";
import { history } from "./utils/history";

import GreekLayout from "@/pages/Layout";
import Login from "@/pages/Login";

import Home from "./pages/Home";
import Article from "./pages/Article";
import Publish from "./pages/Publish";



function App() {
    return (
    // 路由配置
    	<HistoryRouter history={ history }>
      		<div className="App">
				<Routes>
					{/* 不需要鉴权 */}
					<Route path="/login" element={ <Login /> }></Route>
					{/* 需要鉴权 */}
					<Route path="/" element={ 
						<AuthComponent>
							<GreekLayout />
						</AuthComponent>
					}>
						<Route index element={ <Home /> }></Route>
						<Route path="article" element={ <Article /> }></Route>
						<Route path="publish" element={ <Publish /> }></Route>
					</Route>
				</Routes>
      		</div>
    	</HistoryRouter>
  	);
}

export default App;
