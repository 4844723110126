import { Breadcrumb, Card, Form, Radio, Select, DatePicker, Button, Table, Tag, Space, message } from "antd";
import { Link, useNavigate } from "react-router-dom";

// import "moment/locale/zh-cn";
import locale from "antd/es/date-picker/locale/zh_CN";

import "./index.scss";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import img404 from "@/assets/error.png";
import { useEffect, useState } from "react";
import { useStore } from "@/store";
import { observer } from "mobx-react-lite";
import { http } from "@/utils";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Article = () => {

    const { channelStore } = useStore();

    const delArticle = async data => {
        // console.log( data )
        await http.delete( `/mp/articles/${data.id}` );
        message.success( "成功删除" + data );
        setParams({
            ...params,
            page: 1
        });
    }

    const navigate = useNavigate();
    const goPublish = async data => {
        await navigate( `/publish?id=${ data.id }` )
    }

    const columns = [
        {
            title: "封面",
            dataIndex: "cover",
            render: cover => {
                // console.log( cover );
                return <img src={ cover.images[0] || img404 } width={200} height={150} alt="1"/>
            }
        },
        {
            title: "标题",
            dataIndex: "title",
            width: 220
        },
        {
            title: "状态",
            dataIndex: "status",
            render: data => <Tag color="green">审核通过</Tag>
        },
        {
            title: "发布时间",
            dataIndex: "pubdate"
        },
        {
            title: "阅读数",
            dataIndex: "read_count"
        },
        {
            title: "评论数",
            dataIndex: "comment_count"
        },
        {
            title: "点赞数",
            dataIndex: "like_count"
        },
        {
            title: "操作",
            render: data => {
                return (
                    <Space size="middle">
                        <Button 
                            type="primary" 
                            shape="circle" 
                            icon={ <EditOutlined />} 
                            onClick={ () => goPublish( data ) }
                        />
                        <Button 
                            type="primary" 
                            danger 
                            shape="circle" 
                            onClick={() => delArticle( data ) } 
                            icon={ <DeleteOutlined />} 
                        />
                    </Space>
                )
            }
        }
    ]

    // 文章列表管理 统一管理数据 将来更改的时候给 setList 传对象
    const [articleData, setArticleData] = useState({
        list: [], // 文章列表
        count: 0 // 文章数量
    });

    // 文章参数管理
    const [params, setParams] = useState({
        page: 1,
        per_page: 10
    });
    
    useEffect( () => {
        const loadArticleData = async () => {
            const res = await http.get("/mp/articles", { params })
            const { results, total_count } = res.data;
            setArticleData({
                list: results,
                count: total_count
            })
        }

        loadArticleData();
    }, [ params ] )

    const onFinish = values => {
        // console.log( values );
        const { status, channel_id, date } = values;
        // 格式化表单数据
        const _params = {};
        if( status === -1 ){
            return setParams({
                page: 1,
                per_page: 10
            })
        }
        if( channel_id ){
            _params.channel_id = channel_id;
        }
        if( date ) {
            _params.begin_pubdate = date[0].format("YYYY-MM-DD");
            _params.end_pubdate = date[1].format("YYYY-MM-DD");
        }

        // console.log({
        //     ...params,
        //     ..._params
        // })

        setParams({
            ...params,
            ..._params
        })
    }

    const pageChange = page => {
        setParams({
            ...params,
            page
        })
    }

    return (
        <div>
            <Card
                title={
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item>
                            <Link to="/">首页</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>内容管理</Breadcrumb.Item>
                    </Breadcrumb>
                }
                style={{ marginBottom: 20 }}
            >
                <Form 
                    initialValues={{ status: -1 }}
                    onFinish={ onFinish }
                >
                    <Form.Item label="状态" name="status">
                        <Radio.Group>
                            <Radio value={-1}>全部</Radio>
                            <Radio value={0}>草稿</Radio>
                            <Radio value={1}>待审核</Radio>
                            <Radio value={2}>审核通过</Radio>
                            <Radio value={3}>审核失败</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="频道" name="channel_id">
                        <Select
                            placeholder="请选择文章频道"
                            style={{ width: 120 }}
                        >
                        { channelStore.channelList.map( channel => (   
                            <Option value={ channel.id }>{ channel.name }</Option>
                        ) ) }
                        </Select>
                    </Form.Item>

                    <Form.Item label="日期" name="date">
                        {/* 传入 locale 属性 控制中文显示 */}
                        <RangePicker locale={ locale }></RangePicker>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ marginLeft: 80 }}>
                            筛选
                        </Button>
                    </Form.Item>
                </Form>
            </Card>

            <Card title={`根据筛选条件共查询到 ${ articleData.count } 条结果：`}>
                <Table 
                    rowKey="id" 
                    columns={columns} 
                    dataSource={articleData.list} 
                    pagination={{
                        pageSize: params.per_page,
                        total: articleData.count,
                        onChange: pageChange
                    }}
                />
            </Card>
        </div>
    )
}

export default observer( Article );
