import { Card, Form, Input, Checkbox, Button, message } from "antd";
import logo from "@/assets/logo.png";
import "./index.scss";
import { useStore } from "@/store";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const { loginStore } = useStore();
    const navigate = useNavigate();

    const onFinish = async values => {
        // { mobile: '', code: '', remember: bool }
        // values 放置的是所有表单项中用户输入的内容
        try {
            // todo: 登录
            await loginStore.getToken({
                mobile: values.mobile,
                code: values.code
            })
            // 跳转首页
            navigate( "/", { replace: true } );
            // 提示用户
            message.success( "登录成功" );
        } catch ( e ) {
            message.error( e.response.data.message || "登录失败" );
        }
    }

    return (
        <div className="login">
            <Card className="login-container">
                <img className="login-logo" src={ logo } alt="" />
                {/* 登录结果 */}
                <Form
                    name="basic"
                    style={{
                        maxWidth: 600,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                    validateTrigger={['onBlur','onChange']}
                >
                <Form.Item
                    name="mobile"
                    rules={[
                    {
                        required: true,
                        message: '请输入手机号',
                        validateTrigger: "onBlur"
                    },
                    {
                        pattern: /^1[3-9]\d{9}$/,
                        message: '请输入正确的手机号',
                        validateTrigger: "onBlur"
                    }
                    ]}
                >
                    <Input size="large" placeholder="请输入手机号"/>
                </Form.Item>

                <Form.Item
                    name="code"
                    rules={[
                    {
                        required: true,
                        message: '请输入密码',
                        validateTrigger: "onBlur"
                    },
                    {
                        len: 6,
                        message: "请输入 6 位的密码",
                        validateTrigger: "onBlur"
                    }
                    ]}
                >
                    <Input.Password size="large" placeholder="请输入密码"/>
                </Form.Item>

                <Form.Item
                    name="remember"
                    valuePropName="checked"
                >
                    <Checkbox>我已阅读并同意</Checkbox>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" size="large" block>
                    登录
                    </Button>
                </Form.Item>
                </Form>
            </Card>
        </div>
    )
}

export default Login;
