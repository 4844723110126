import { 
    Card, 
    Breadcrumb,
    Form,
    Button,
    Radio,
    Input,
    Upload,
    Space,
    Select,
    message
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "./index.scss";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useStore } from "@/store";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { http } from "@/utils";

const { Option } = Select;

const Publish = () => {
    const { channelStore } = useStore();
    // 存储上传图片的列表
    const [ fileList, setFileList ] = useState([]);
    
    // 用 useRef 声明一个暂存仓库
    const cacheImgList = useRef([]);

    const onUploadChange = ({ fileList }) => {
        const formatList = fileList.map( file => {
            // 上传完毕 格式处理
            if ( file.response ) {
                return {
                    uid: file.uid,
                    url: file.response.data.url
                }
            }
            // 否则上传中 不做处理
            return file;
        } );
        
        setFileList( fileList );
        
        // 同时把图片列表填入仓库里面
        cacheImgList.current = fileList;
    }


    // 切换图片
    const [ imgCount, setImgCount ] = useState( 1 );
    const radioChange = e => {
        const rawValue = e.target.value;

        setImgCount( rawValue );  
        
        // 从仓库里面取出对应的图片数量
        // 重新交给用来渲染图片的 fileList
        // 通过 setFileList

        if ( !cacheImgList.current ) return false;

        if ( rawValue === 1 ) {
            const img = cacheImgList.current[0];
            setFileList([ img ]);
        }else if ( rawValue === 3 ) {
            setFileList( cacheImgList.current );
        }
    }

    // 编辑功能
    const [ params ] = useSearchParams();
    const id = params.get("id");
    const form = useRef(null);
    // console.log( "route: ", id );
    // 数据回填
    useEffect( () => {
        const loadDetail = async () => {
            const res = await http.get(`/mp/articles/${ id }`);
            const rawData = res.data;
            form.current.setFieldsValue( { ...rawData, type: rawData.cover.type } );
            const formatImgList = rawData.cover.images.map( url => ({ url }) );
            setFileList( formatImgList );
            cacheImgList.current = formatImgList;
        }
        if ( id ) {
            loadDetail();
        }
    }, [ id ] )

    const navigate = useNavigate();
    // 提交表单
    const onFinish = async values => {
        // console.log( values );
        const { channel, content, type, title } = values;
        const params = {
            channel,
            content,
            title,
            type,
            cover: {
                type: type,
                images: fileList.map( item => item.url )
            }
        }
        if ( id ) {
            await http.put( `/mp/articles/${ id }?draft=false`, params );
        }else {
            await http.post( "/mp/articles?draft=false", params );
        }
        
        // 跳转列表 提示用户
        navigate("/article");
        message.success(`${ id ? "更新成功" : "发布成功" }`);
    }


    return (
        <div className="publish">
            <Card
                title={
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item>
                            <Link to="/">首页</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{ id ? "编辑" : "发布" }文章</Breadcrumb.Item>
                    </Breadcrumb>
                }
            >
                <Form
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ type: 1, content: "this is content" }}
                    onFinish={ onFinish }
                    ref={ form }
                >
                    <Form.Item
                        label="标题"
                        name="title"
                        rules={[{ required: true, message: "请输入文章标题" }]}
                    >
                        <Input placeholder="请输入文章标题" style={{ width: 400 }} />
                    </Form.Item>
                    <Form.Item
                        label="频道"
                        name="channel"
                        rules={[{ required: true, message: "请选择文章频道" }]}
                    >
                        <Select placeholder="请选择文章频道" style={{ width: 400 }}>
                            { channelStore.channelList.map( item => (
                                <Option value={ item.id } key={ item.id }>{ item.name }</Option>
                            ) ) }
                        </Select>
                    </Form.Item>

                    <Form.Item label="封面">
                        <Form.Item name="type">
                            <Radio.Group onChange={ radioChange }>
                                <Radio value={1}>单图</Radio>
                                <Radio value={3}>三图</Radio>
                                <Radio value={0}>无图</Radio>
                            </Radio.Group>
                        </Form.Item>
                        { imgCount > 0 && (
                            <Upload
                                name="image"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList
                                action="http://geek.itheima.net/v1_0/upload"
                                fileList={ fileList }
                                onChange={ onUploadChange }
                                multiple={ imgCount > 1 }
                                maxCount={ imgCount }
                            >
                                <div style={{ marginTop: 0 }}>
                                    <PlusOutlined />
                                </div>
                            </Upload>
                        ) }
                    </Form.Item>
                    <Form.Item
                        label="内容"
                        name="content"
                        rules={[{ required: true, message: "请输入文章内容" }]}
                    >
                        <ReactQuill theme="snow"/>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 4 }}>
                        <Space>
                            <Button size="large" type="primary" htmlType="submit">
                                { id ? "编辑" : "发布" }文章
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    )
}

export default observer( Publish );
