// login module

import { http, setToken, getToken, removeToken } from "@/utils";
import { makeAutoObservable } from "mobx";

class LoginStore {
    token = getToken() || "";
    
    constructor () {
        // 响应式
        makeAutoObservable( this );
    }    

    getToken = async ({ mobile, code }) => {
        // 调用登录接口
        const res = await http.post( "http://geek.itheima.net/v1_0/authorizations", {
            mobile, code
        } )
        // 存入 token
        this.token = res.data.token;
        // 存入 ls
        setToken( this.token );
    }

    loginOut = () => {
        this.token = "";
        removeToken();
    }
}

export default LoginStore;
